import React from 'react';
import Accordion from 'react-bootstrap/Accordion';


const FAQSection = () => {
  return (
    <div className="faq-section">
      <h2 className="faq-header">Frequently Asked Questions</h2>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>What is puberty?</Accordion.Header>
          <Accordion.Body>
            Puberty happens to everyone! It's a natural process where your body goes through changes, preparing you for adulthood.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>What are the signs of puberty?</Accordion.Header>
          <Accordion.Body>
            Signs of puberty include physical changes such as growth spurts, the development of secondary sexual characteristics, and hormonal changes.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>How long does puberty last?</Accordion.Header>
          <Accordion.Body>
            Puberty typically lasts several years and can vary for each person, generally occurring between the ages of 9 and 16.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default FAQSection;
