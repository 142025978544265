import React from 'react';

import { Link } from 'react-router-dom';

const blogsData = [
  {
    id: 1,
    title: "The Importance of Hygiene in Women's Health",
    date: "September 20, 2023",
    summary: "Discover how maintaining good hygiene is vital for women's health and well-being, with tips on best practices and products.",
  },
  {
    id: 2,
    title: "Eco-Friendly Sanitary Napkins: A Better Choice",
    date: "August 15, 2023",
    summary: "Explore the benefits of using eco-friendly sanitary napkins for the environment and your personal health.",
  },
  {
    id: 3,
    title: "Finding the Right Sanitary Napkin for You",
    date: "July 5, 2023",
    summary: "Choosing the right sanitary napkin can be challenging. This guide will help you make an informed decision.",
  },
  {
    id: 4,
    title: "Sanitary Napkins: Myths and Facts",
    date: "June 10, 2023",
    summary: "Uncover the most common myths surrounding sanitary napkins and learn the facts for making better choices.",
  },
  {
    id: 5,
    title: "How to Dispose of Sanitary Napkins Safely",
    date: "May 22, 2023",
    summary: "Proper disposal of sanitary napkins is crucial for hygiene and the environment. Here’s how you can do it right.",
  },
  {
    id: 6,
    title: "Menstrual Health and Nutrition",
    date: "April 8, 2023",
    summary: "Learn how maintaining a balanced diet can significantly impact menstrual health and overall well-being.",
  },
];

const Blogs = () => {
  return (
<div>
<div className="container-fluid bg-primary hero-header mb-5">
    <div className="container text-center">
      <h1 className="display-4 text-white mb-3 animated slideInDown">Blogs</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
          <li className="breadcrumb-item"><a className="text-white" href="#">About</a></li>
          <li className="breadcrumb-item text-white active" aria-current="page">Blogs</li>
        </ol>
      </nav>
    </div>
  </div>
    <div className="blogs-container">
      <h2 className="blogs-header">Latest Blog Posts</h2>
      <div className="blogs-grid">
        {blogsData.map((blog) => (
          <div key={blog.id} className="blog-item">
            <div className="blog-content">
              <h3 className="blog-title">{blog.title}</h3>
              <p className="blog-date">{blog.date}</p>
              <p className="blog-summary">{blog.summary}</p>
       
            </div>
          </div>
        ))}
      </div>
    </div>
</div>
  );
};

export default Blogs;
