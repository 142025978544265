import React from 'react';


const awardsData = [
  {
    title: "Zee Marathi Uncha Maza Zoka Award",
    year: 2023,
    image: "assets/img/zeemarathi.jpeg", // Add your image path here
  },
  {
    title: "पुण्यश्लोक अहिल्यादेवी होळकर महिला स्टार्टअप योजना विजेते २०२४",
    year: 2024,
    image: "assets/img/WhatsApp Image 2024-09-26 at 9.51.50 AM.jpeg",
  },
  {
    title: "LiFE Innovation Challenge by MAGIC - Promising Women- led Startup",
    year:  2023,
    image: "assets/img/life.jpeg",
  },
];

const Awards = () => {
  return (
   <div>
     <div className="container-fluid bg-primary hero-header mb-5">
        <div className="container text-center">
          <h1 className="display-4 text-white mb-3 animated slideInDown">Awards</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
         
              <li className="breadcrumb-item"><a className="text-white" href="#">About</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Contact Us</li>
            </ol>
          </nav>
        </div>
      </div>
     <div className="awards-section">
        <h2 className="awards-title">Our Awards and Recognitions</h2>
        <div className="awards-grid">
          {awardsData.map((award, index) => (
            <div key={index} className="award-card">
              <img src={award.image} alt={award.title} className="award-image" />
              <h3 className="award-title">{award.title}</h3>
              <p className="award-year">{award.year}</p>
            </div>
          ))}
        </div>
      </div>
   </div>
  );
};

export default Awards;
