import React from 'react';
import './Community.css'; // Optional: Import CSS for additional styling

const Community = () => {
  return (
   <div>
    <div className="container-fluid bg-primary hero-header mb-5">
        <div className="container text-center">
          <h1 className="display-4 text-white mb-3 animated slideInDown">Community Impact</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
             
              <li className="breadcrumb-item"><a className="text-white" href="#">About</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Community Impact</li>
            </ol>
          </nav>
        </div>
      </div>
     <section className="community-section">
      <div className="container">
        <h1 className="community-title">Community Impact</h1>
        <p className="community-paragraph">
          Hapeefive creates jobs, provides women with financial independence, and boosts local economies. The eco-friendly approach reduces agricultural waste and promotes sustainability. By making affordable, biocompatible products, it improves menstrual hygiene access, especially in underserved areas. This also breaks menstrual taboos and raises health awareness. Additionally, using banana pulp supports farmers, creating a circular economy. Overall, the startup empowers women, promotes sustainability, and enhances community well-being.
        </p>
      </div>
    </section>
   </div>
  );
}

export default Community;
