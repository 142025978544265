import React from 'react';
import './Product.css';

const Product = () => {
  return (
    <div>
      <div className="container-fluid bg-primary hero-header mb-5">
        <div className="container text-center">
          <h1 className="display-4 text-white mb-3 animated slideInDown">Products</h1>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <h1 className="text-primary mb-3"><span className="fw-light text-dark">Our Hapeefive</span> Sanitary Pads</h1>
            <p className="mb-5">Discover our top-quality sanitary pads designed for maximum comfort and protection. Perfect for every day of your cycle.</p>
          </div>
          <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="product-item text-center border h-100 p-4">
                <img className="img-fluid mb-4" src="assets/img/pantyliner.jpg" alt="Panty Liner" />
                <div className="mb-2">
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small>(85)</small>
                </div>
                <a href="" className="h6 d-inline-block mb-2">Sanitary Napkins(8 Pads)</a>
                <p>XL Size (320 mm long)</p>
                <h5 className="text-primary mb-3">&#8377; 89/-</h5>
                <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="product-item text-center border h-100 p-4">
                <img className="img-fluid mb-4" src="assets/img/WhatsApp Image 2024-09-16 at 4.10.03 PM.jpeg" alt="Maternity Pad" />
                <div className="mb-2">
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small>(90)</small>
                </div>
                <a href="" className="h6 d-inline-block mb-2">Maternity Pads(5 pads)</a>
                <p>XXXL Size (400 mm long)</p>
                <h5 className="text-primary mb-3"> &#8377; 125/-</h5>
                <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="product-item text-center border h-100 p-4">
                <img className="img-fluid mb-4" src="assets/img/DSC07366.jpg" alt="Sanitary Pad" />
                <div className="mb-2">
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small className="fa fa-star text-primary"></small>
                  <small>(120)</small>
                </div>
                <a href="" className="h6 d-inline-block mb-2">Sanitary Pads(30 Pads)</a>
                <p>XXL Size (340 mm long)</p>
                <h5 className="text-primary mb-3">&#8377; 600/-</h5>
                <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="video-container">
        <video src='assets/video/vdo.mp4' controls></video>
        <div className="play-button"></div>
      </div>

      <div className="container-fluid py-5 bg-light">
        <div className="container position-relative">
          <h2 className="text-center mb-4">Our Customers Love Us!</h2>
          <div id="reviewCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {/* Carousel Item 1 - showing 3 reviews */}
              <div className="carousel-item active">
                <div className="d-flex"> {/* Flex container for horizontal layout */}
                  <div className="col-md-4 d-flex align-items-stretch">
                    <div className="card p-3 border shadow-sm h-100">
                      <h6 className="mb-3">Komal Valecha</h6>
                      <p>
                        After trying many pads from well-established brands, this is
                        the best pad so far! It doesn't cause irritation and is also
                        environmentally friendly. Good absorbency, odorless, and
                        super comfortable. Just go for it, girls!
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 d-flex align-items-stretch">
                    <div className="card p-3 border shadow-sm h-100">
                      <h6 className="mb-5">Upasana Mandhan</h6>
                      <p>
                        I am very happy with Hapeefive sanitary napkins. Soft, no leakage,
                        no odor, and they stay in place. I recommend all women try these.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 d-flex align-items-stretch">
                    <div className="card p-3 border shadow-sm h-100">
                      <h6 className="mb-5">Rucha Rokade</h6>
                      <p>
                        Aruheal's concept is amazing, as it cares for both hygiene and
                        the environment. The biodegradability of the napkin is my favorite
                        feature!
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add more carousel items as needed */}
            </div>

            {/* Carousel Controls (Prev and Next) */}
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#reviewCarousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#reviewCarousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
